
#main-nav {
    @media only screen and (max-width:1280px) {
        justify-content: space-evenly !important;
        .logo{
            display: none;
        }
    }

    @media only screen and (min-width:1030px) {
       li{
           display: block !important;
       }
      }


    @media only screen and (max-width:1029px) {

        .nav-bars-icon{
            display: block !important;
        }

        ul{
            display: block !important;
            // text-align: center;
            width: 100%;

            .nav-bars-icon{
                font-size: 20px;
            }

            

            .nav-bar-links{
                margin-top:10px;
                display: block !important;
            } 
            li{
                // margin: 10px 0 !important;
                display: none;
                a{
                    display: block;
                    width: 100%;
                    padding: 0.7rem 0 !important;
                    text-align: center;
                    // margin:auto !important;
                    // border-bottom: 1px solid black !important;
                }

                a:hover{
                    background-color:$main-color;
                    color:#fff !important; 
                }

                // a::after{
                //     height: 0px;
                //   }

                a:hover::after,
                a:focus:after,
                a:active:after
                {
                    width: 0 !important;
                    left: 0;
                    right:auto;
                }

                .current{
                    background-color:$main-color;
                    border-radius:5px;
                    color:#fff !important;
                }

                .current::after{
                    width: 0 !important;
                    left: 0;
                    right:auto;
                }
            }
            
        }
    }

    @media only screen and (max-width:420px) {
        padding:1rem 2rem !important;
    }
}
